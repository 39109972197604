* {
color: #292930;font-family: 'Toyota Type';--bs-primary: #d40605;--bs-primary-rgb: 212,6,5;--bs-secondary: #292930;--bs-secondary-rgb: 41,41,48;--bs-success: #505050;--bs-success-rgb: 80,80,80;--bs-info: #a0a0a0;--bs-info-rgb: 160,160,160;--bs-warning: #d40605;--bs-warning-rgb: 212,6,5;--bs-danger: #d40605;--bs-danger-rgb: 212,6,5;--bs-light: #c8c8c8;--bs-light-rgb: 200,200,200;--bs-body-color: #292930;--bs-dark: #292930;--bs-dark-rgb: 41,41,48;--bs-body-bg: #f6f6f6;--bs-body-font-weight: normal;
}

#form_signup_submit {
  pointer-events: auto;
}

@font-face {
	font-family: 'Toyota Type Book';
	src: url(../fonts/ToyotaType-Book.woff2) format('woff2'),
	url(../fonts/ToyotaType-Book.woff) format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Toyota Type Book';
	src: url(../fonts/ToyotaType-Book.woff2) format('woff2'),
	url(../fonts/ToyotaType-Book.woff) format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}



@font-face {
	font-family: 'Toyota Type';
	src: url(../fonts/ToyotaType-Light.woff2) format('woff2'),
	url(../fonts/ToyotaType-Light.woff) format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Toyota Type';
	src: url(../fonts/ToyotaType-Light.woff2) format('woff2'),
	url(../fonts/ToyotaType-Light.woff) format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: 'Toyota Type';
	src: url(../fonts/ToyotaType-Regular.woff2) format('woff2'),
	url(../fonts/ToyotaType-Regular.woff) format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Toyota Type';
	src: url(../fonts/ToyotaType-Regular.woff2) format('woff2'),
	url(../fonts/ToyotaType-Regular.woff) format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}



img {
  max-width: 100%;
}

.row {
  max-width: 933px;
}

#submit_mainpage, .toy_submit {
  -webkit-transition: color 0.3s ease-in-out,background-color 0.3s ease-in-out,border-color 0.3s ease-in-out,-webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out,background-color 0.3s ease-in-out,border-color 0.3s ease-in-out,-webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out,background-color 0.3s ease-in-out,border-color 0.3s ease-in-out,box-shadow -0.3s ease-in-out;
  transition: color 0.3s ease-in-out,background-color 0.3s ease-in-out,border-color 0.3s ease-in-out,box-shadow 0.3s ease-in-out,-webkit-box-shadow 0.3s ease-in-out;
  background: var(--bs-secondary);
  font-size: 21px;
  border-radius: 0;
  box-shadow: 0px 7px 7px 0px rgba(41,41,48,0.29);
  max-width: 340px;
  padding-top: 10px;
  padding-bottom: 14px;
}

#submit_mainpage:hover, .toy_submit:hover {
  background-color: var(--bs-success)!important;
  /*color: #393943;*/
  /*border-color: #393943!important;*/
  box-shadow: none!important;
}

@media (max-width: 500px) {
  #submit_mainpage, .toy_submit {
    width: 100%!important;
    max-width: 100%!important;
  }
}

a:hover {
  text-decoration: none;
  color: initial;
}

a {
  text-decoration: underline;
}

h4 {
  font-family: 'Toyota Type Book';
  line-height: 1.7;
}

.form-label {
  font-size: 17px;
}

::-moz-selection {
  background: #f02;
  color: #fff;
}

::selection {
  background: #f02;
  color: #fff;
}

form {
  padding-bottom: 150px;
}

.legals {
  font-size: 12px!important;
}

small {
  font-size: 12px;
  line-height: 1.2;
  padding-top: 6px;
  color: var(--bs-gray);
}

#posiada {
  font-size: 15px;
  font-family: 'Toyota Type Book';
  color: var(--bs-secondary);
}

#input_vin {
  display: none;
}

@media (min-width: 300px) {
  h4 {
    font-size: 16px;
    line-height: 1.3;
  }
}

@media (min-width: 576px) {
  h4 {
    font-size: 18px;
    line-height: 1.4;
  }
}

@media (min-width: 768px) {
  h4 {
    font-size: 22px;
    line-height: 1.5;
  }
}

.radio_posiada label {
  font-family: 'Toyota Type Book';
  font-size: 15px;
  line-height: 18px;
}

@media only screen and (min-width: 576px) {
  .form__row-inline input {
    width: 100% !important;
  }
}

.is-short input {
  display: inline-block;
  width: auto;
}

.label {
  display: block;
  font-family: 'Toyota Type Book';
  margin-bottom: 7px;
}

.label a:hover {
  color: var(--bs-primary);
}

.label.form__file {
  align-items: center;
  /*display: flex;*/
  font-size: 1.4em;
  /*justify-content: center;*/
  min-height: 47px;
}

@media only screen and (min-width: 992px) {
  .label.form__file, label.form__file {
    /*font-size: 1.8em;*/
  }
}

.input, .select {
  border: 1px solid var(--bs-info);
  border-radius: 2px;
  display: block;
  font-family: 'Toyota Type';
  /*box-shadow: 0px 0px 7px 0px var(--bs-light);*/
  /*font-size: 1.8em;*/
  /*padding: 9px 15px;*/
  /*width: 100%;*/
}

.form .op-0 {
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  white-space: nowrap;
}

.form__file {
  background-color: var(--bs-secondary);
  border: 1px solid var(--bs-secondary);
  border-radius: 2px;
  color: var(--bs-white);
  cursor: pointer;
  display: block;
  font-family: 'Toyota Type';
  font-size: 1.8em;
  overflow: hidden;
  padding: 9px 15px;
  position: relative;
  text-align: center;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  width: 100%;
}

.form__file:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.form__file input {
  cursor: pointer;
  height: 1px;
  left: -1px;
  opacity: 0;
  position: absolute;
  top: -1px;
  width: 1px;
}

.form__radiobox {
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 3px;
  padding-left: 35px;
  position: relative;
}

.form__radiobox label {
  cursor: pointer;
  pointer-events: all;
  position: relative;
}

.form__radiobox label:before {
  background-color: var(--bs-white);
  border: 1px solid var(--bs-secondary);
  border-radius: 50%;
  content: "";
  display: block;
  height: 25px;
  left: -35px;
  position: absolute;
  top: 3px;
  width: 25px;
}

.form__radiobox label:after {
  background-color: var(--bs-primary);
  border-radius: 50%;
  content: "";
  display: none;
  height: 10px;
  left: -22px;
  position: absolute;
  top: 11px;
  transform: translate(-50%);
  width: 10px;
}

.form__radiobox input {
  cursor: pointer;
  height: 1px;
  left: -1px;
  opacity: 0;
  position: absolute;
  top: -1px;
  width: 1px;
}

.form__radiobox input:checked + label:after {
  display: block;
}

.form__checkbox {
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 3px;
  padding-left: 35px;
  position: relative;
  color: var(--bs-gray-300);
}

.form__checkbox label {
  cursor: pointer;
  pointer-events: all;
  position: relative;
  font-size: 16px;
  color: var(--bs-secondary);
  line-height: 140%;
  font-family: 'Toyota Type Book';
}

.form__checkbox label:before {
  background-color: var(--bs-white);
  border: 1px solid var(--bs-secondary);
  content: "";
  display: block;
  height: 25px;
  left: -35px;
  position: absolute;
  /*top: 3px;*/
  width: 25px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(41,41,48,0.29);
}

.form__checkbox label:after {
  border: solid var(--bs-primary);
  border-width: 0 3px 3px 0;
  content: "";
  display: none;
  height: 13px;
  left: -25px;
  position: absolute;
  top: 5px;
  transform: rotate(45deg);
  width: 5px;
}

.form__checkbox input {
  cursor: pointer;
  height: 1px;
  left: -1px;
  opacity: 0;
  position: absolute;
  top: -1px;
  width: 1px;
}

.form__checkbox input:checked + label:after {
  display: block;
}

.form-check-label {
  font-size: 15px;
}

.input_with_label .form-control {
  padding-top: 0.4rem;
  padding-bottom: 0.6rem;
}

.form__checkbox label:before {
  box-shadow: none!important;
  border-color: var(--bs-info);
  border-radius: 4px;
  height: 20px;
  left: -34px;
  top: 2px;
  width: 20px;
}

.form__checkbox label:after {
  height: 10px;
  left: -27px;
  top: 6px;
  width: 6px;
}

.form__checkbox label.fix1:before {
    top: 0px;
}
.form__checkbox label.fix1:after {
    top: 4px;
}

.form__send-form-wrapper .button {
  display: block;
  width: 100%;
}

.input_with_label label {
  font-family: 'Toyota Type Book'!important;
  font-size: 15px;
  margin-bottom: 10px!important;
}

.input_with_label input {
  /*margin-bottom: 0px;*/
  font-size: 16px;
  /*margin-bottom: 20px;*/
  top: -4px;
}

.form-control:focus {
  border-color: rgba(80,80,80,0.62)!important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 2px 8px var(--bs-light);
  /*box-shadow: 0px 0px 7px 0px var(--bs-light);*/
}

.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
  padding-top: 4px;
  cursor: pointer;
  font-size: 16px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control_indicator {
  position: absolute;
  top: 3px;
  left: 0;
  height: 22px;
  width: 22px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: undefinedpx;
}

.control:hover input ~ .control_indicator, .control input:focus ~ .control_indicator {
  background: #ddddd;
}

.control input:checked ~ .control_indicator {
  background: #ffffff;
}

.control:hover input:not([disabled]):checked ~ .control_indicator, .control input:checked:focus ~ .control_indicator {
  background: #0e6647d;
}

.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}

.control input:checked ~ .control_indicator:after {
  display: block;
}

.control-radio .control_indicator {
  border-radius: 50%;
}

.control-radio .control_indicator:after {
  left: 4px;
  top: 4px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ff0000;
  transition: background 250ms;
}

.control-radio input:disabled ~ .control_indicator:after {
  background: #7b7b7b;
}


.header_logo {
  max-height: 120px;
}

nav {
  background-color: #ffffff;
  border-bottom: 1px solid #cecfd0;
  box-shadow: 0 7px 10px 0 rgba(0,0,0,.16);
}
