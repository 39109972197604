* {
  color: #292930;
  --bs-primary: #d40605;
  --bs-primary-rgb: 212, 6, 5;
  --bs-secondary: #292930;
  --bs-secondary-rgb: 41, 41, 48;
  --bs-success: #505050;
  --bs-success-rgb: 80, 80, 80;
  --bs-info: #a0a0a0;
  --bs-info-rgb: 160, 160, 160;
  --bs-warning: #d40605;
  --bs-warning-rgb: 212, 6, 5;
  --bs-danger: #d40605;
  --bs-danger-rgb: 212, 6, 5;
  --bs-light: #c8c8c8;
  --bs-light-rgb: 200, 200, 200;
  --bs-body-color: #292930;
  --bs-dark: #292930;
  --bs-dark-rgb: 41, 41, 48;
  --bs-body-bg: #f6f6f6;
  --bs-body-font-weight: normal;
  font-family: Toyota Type;
}

#form_signup_submit {
  pointer-events: auto;
}

@font-face {
  font-family: Toyota Type Book;
  src: url("ToyotaType-Book.a8058f2a.woff2") format("woff2"), url("ToyotaType-Book.82d54602.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota Type Book;
  src: url("ToyotaType-Book.a8058f2a.woff2") format("woff2"), url("ToyotaType-Book.82d54602.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota Type;
  src: url("ToyotaType-Light.1f2480e8.woff2") format("woff2"), url("ToyotaType-Light.8e52aab2.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota Type;
  src: url("ToyotaType-Light.1f2480e8.woff2") format("woff2"), url("ToyotaType-Light.8e52aab2.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota Type;
  src: url("ToyotaType-Regular.df898fd1.woff2") format("woff2"), url("ToyotaType-Regular.6e2b15e7.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota Type;
  src: url("ToyotaType-Regular.df898fd1.woff2") format("woff2"), url("ToyotaType-Regular.6e2b15e7.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

img {
  max-width: 100%;
}

.row {
  max-width: 933px;
}

#submit_mainpage, .toy_submit {
  background: var(--bs-secondary);
  max-width: 340px;
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 14px;
  font-size: 21px;
  transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out, box-shadow .3s ease-in-out, -webkit-box-shadow .3s ease-in-out;
  box-shadow: 0 7px 7px #2929304a;
}

#submit_mainpage:hover, .toy_submit:hover {
  background-color: var(--bs-success) !important;
  box-shadow: none !important;
}

@media (max-width: 500px) {
  #submit_mainpage, .toy_submit {
    width: 100% !important;
    max-width: 100% !important;
  }
}

a:hover {
  color: initial;
  text-decoration: none;
}

a {
  text-decoration: underline;
}

h4 {
  font-family: Toyota Type Book;
  line-height: 1.7;
}

.form-label {
  font-size: 17px;
}

::selection {
  color: #fff;
  background: #f02;
}

form {
  padding-bottom: 150px;
}

.legals {
  font-size: 12px !important;
}

small {
  color: var(--bs-gray);
  padding-top: 6px;
  font-size: 12px;
  line-height: 1.2;
}

#posiada {
  color: var(--bs-secondary);
  font-family: Toyota Type Book;
  font-size: 15px;
}

#input_vin {
  display: none;
}

@media (min-width: 300px) {
  h4 {
    font-size: 16px;
    line-height: 1.3;
  }
}

@media (min-width: 576px) {
  h4 {
    font-size: 18px;
    line-height: 1.4;
  }
}

@media (min-width: 768px) {
  h4 {
    font-size: 22px;
    line-height: 1.5;
  }
}

.radio_posiada label {
  font-family: Toyota Type Book;
  font-size: 15px;
  line-height: 18px;
}

@media only screen and (min-width: 576px) {
  .form__row-inline input {
    width: 100% !important;
  }
}

.is-short input {
  width: auto;
  display: inline-block;
}

.label {
  margin-bottom: 7px;
  font-family: Toyota Type Book;
  display: block;
}

.label a:hover {
  color: var(--bs-primary);
}

.label.form__file {
  min-height: 47px;
  align-items: center;
  font-size: 1.4em;
}

.input, .select {
  border: 1px solid var(--bs-info);
  border-radius: 2px;
  font-family: Toyota Type;
  display: block;
}

.form .op-0 {
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
}

.form__file {
  background-color: var(--bs-secondary);
  border: 1px solid var(--bs-secondary);
  color: var(--bs-white);
  cursor: pointer;
  text-align: center;
  width: 100%;
  border-radius: 2px;
  padding: 9px 15px;
  font-family: Toyota Type;
  font-size: 1.8em;
  transition: background-color .3s ease-in-out, border-color .3s ease-in-out;
  display: block;
  position: relative;
  overflow: hidden;
}

.form__file:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.form__file input {
  cursor: pointer;
  height: 1px;
  opacity: 0;
  width: 1px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.form__radiobox {
  cursor: pointer;
  padding-bottom: 3px;
  padding-left: 35px;
  position: relative;
  overflow: hidden;
}

.form__radiobox label {
  cursor: pointer;
  pointer-events: all;
  position: relative;
}

.form__radiobox label:before {
  background-color: var(--bs-white);
  border: 1px solid var(--bs-secondary);
  content: "";
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 3px;
  left: -35px;
}

.form__radiobox label:after {
  background-color: var(--bs-primary);
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 11px;
  left: -22px;
  transform: translate(-50%);
}

.form__radiobox input {
  cursor: pointer;
  height: 1px;
  opacity: 0;
  width: 1px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.form__radiobox input:checked + label:after {
  display: block;
}

.form__checkbox {
  cursor: pointer;
  color: var(--bs-gray-300);
  padding-bottom: 3px;
  padding-left: 35px;
  position: relative;
  overflow: hidden;
}

.form__checkbox label {
  cursor: pointer;
  pointer-events: all;
  color: var(--bs-secondary);
  font-family: Toyota Type Book;
  font-size: 16px;
  line-height: 140%;
  position: relative;
}

.form__checkbox label:before {
  background-color: var(--bs-white);
  border: 1px solid var(--bs-secondary);
  content: "";
  height: 25px;
  width: 25px;
  border-radius: 5px;
  display: block;
  position: absolute;
  left: -35px;
  box-shadow: 0 0 5px #2929304a;
}

.form__checkbox label:after {
  border: solid var(--bs-primary);
  content: "";
  height: 13px;
  width: 5px;
  border-width: 0 3px 3px 0;
  display: none;
  position: absolute;
  top: 5px;
  left: -25px;
  transform: rotate(45deg);
}

.form__checkbox input {
  cursor: pointer;
  height: 1px;
  opacity: 0;
  width: 1px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.form__checkbox input:checked + label:after {
  display: block;
}

.form-check-label {
  font-size: 15px;
}

.input_with_label .form-control {
  padding-top: .4rem;
  padding-bottom: .6rem;
}

.form__checkbox label:before {
  border-color: var(--bs-info);
  height: 20px;
  width: 20px;
  border-radius: 4px;
  top: 2px;
  left: -34px;
  box-shadow: none !important;
}

.form__checkbox label:after {
  height: 10px;
  width: 6px;
  top: 6px;
  left: -27px;
}

.form__checkbox label.fix1:before {
  top: 0;
}

.form__checkbox label.fix1:after {
  top: 4px;
}

.form__send-form-wrapper .button {
  width: 100%;
  display: block;
}

.input_with_label label {
  font-size: 15px;
  margin-bottom: 10px !important;
  font-family: Toyota Type Book !important;
}

.input_with_label input {
  font-size: 16px;
  top: -4px;
}

.form-control:focus {
  box-shadow: inset 0 1px 1px #00000013, 0 2px 8px var(--bs-light);
  border-color: #5050509e !important;
}

.control {
  cursor: pointer;
  margin-bottom: 8px;
  padding-top: 4px;
  padding-left: 30px;
  font-family: arial;
  font-size: 16px;
  display: block;
  position: relative;
}

.control input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.control_indicator {
  height: 22px;
  width: 22px;
  border-radius: undefinedpx;
  background: #fff;
  border: 1px solid #ccc;
  position: absolute;
  top: 3px;
  left: 0;
}

.control:hover input ~ .control_indicator, .control input:focus ~ .control_indicator {
  background: #ddddd;
}

.control input:checked ~ .control_indicator {
  background: #fff;
}

.control:hover input:not([disabled]):checked ~ .control_indicator, .control input:checked:focus ~ .control_indicator {
  background: #0e6647d;
}

.control input:disabled ~ .control_indicator {
  opacity: .6;
  pointer-events: none;
  background: #e6e6e6;
}

.control_indicator:after {
  box-sizing: unset;
  content: "";
  display: none;
  position: absolute;
}

.control input:checked ~ .control_indicator:after {
  display: block;
}

.control-radio .control_indicator {
  border-radius: 50%;
}

.control-radio .control_indicator:after {
  height: 12px;
  width: 12px;
  background: red;
  border-radius: 50%;
  transition: background .25s;
  top: 4px;
  left: 4px;
}

.control-radio input:disabled ~ .control_indicator:after {
  background: #7b7b7b;
}

.header_logo {
  max-height: 120px;
}

nav {
  background-color: #fff;
  border-bottom: 1px solid #cecfd0;
  box-shadow: 0 7px 10px #00000029;
}



/*# sourceMappingURL=index.9fec2f70.css.map */
